@keyframes flotar {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70px);
  }
}

@keyframes shadow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.3);
  }
}

.ghost {
  height: 250px;
  animation: flotar 2s infinite ease-in-out alternate;
}

.shadow {
  width: 150px;
  padding: 1em;
  background-color: #444;
  border-radius: 50%;
  filter: blur(10px);
  animation: shadow 2s infinite ease-in-out alternate;
}
