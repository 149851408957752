.error-container {
  background-color: #333;
  width: 100vw;
  height: 100vh;
  color: #fff;
  font-family: 'Arial Black';
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eye {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  position: relative;
  width: 100px;

  &::after {
    background: #000;
    border-radius: 50%;
    bottom: calc(0.561 * 100px);
    content: '';
    height: 33px;
    position: absolute;
    right: calc(0.33 * 100px);
    width: 33px;
  }
}

p.sub-text {
  margin-bottom: 4rem;
  max-width: calc(100% - 70px);
}

button.reset-button {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: lightgray;
  }
}
